html,
body,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ol,
form,
input,
textarea,
th,
td,
select {
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #333;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

img {
  border: none;
  vertical-align: middle;
}

a {
  text-decoration: none;
  color: #232323;

}

.tt_ReactDOM {
  /*选择图库时蒙层样式*/
  position: fixed !important;
}

i {
  font-style: normal;
  /* !*富文本里有设置倾斜*! */
  /*可以在富文本组件里单独处理样式倾斜，在这里注释这个样式影响会很大*/
}

#root {
  width: 100%;
  min-height: 100%;
  background: #f6f7fb;
  font-family: Noto Sans SC;
}

.resource_center_wrap {
  max-width: 1600px;
  margin: 0 auto;
  min-width: 1200px;
  padding: 78px 35px 30px 49px;
}

.resource_center_wrap .footer {
  width: 100%;
  position: fixed;
  height: 80px;
  line-height: 80px;
  background: #FFFFFF;
  box-shadow: 0px -4px 15px rgba(13, 23, 49, 0.04);
  left: 0;
  bottom: 0;
  z-index: 1;
}

.resource_center_wrap .footer .footer_cont {
  max-width: 1600px;
  min-width: 1200px;
  padding: 0 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.resource_center_wrap .footer .ant-radio-wrapper {
  cursor: auto;
}

.resource_center_wrap .footer p .ant-btn {
  margin-left: 30px;
  border-radius: 5px;
}

/* 分页样式start */
#root .ant-pagination-item-link,
#root .ant-pagination-item {
  border: none;
}

#root .ant-select-selection {
  border-radius: 3px;
  border: 1px solid #DCDFE6;
  /* height: 29px; */
  /* margin-top: -2px; */
}

#root .ant-select-selection:hover {
  color: #3E78ED;
}

#root .ant-select-selection__rendered {
  line-height: 27px;
  margin-right: 35px;
}

#root .ant-select-lg .ant-select-selection__rendered {
  line-height: 38px;

}

/* #root .ant-pagination-options {
  float: left;
}
#root .ant-pagination-options {
  float: left;
} */
#root .main_container .ant-pagination-options {
  float: inherit;
}

#root .student_study .ant-pagination-options {
  float: inherit;
}

/* 分页样式end */


/* 弹窗底部样式start */
.modal_footer {
  padding-right: 20px;
}

.modal_footer .ant-btn {
  width: 91px;
  height: 39px;
  font-size: 16px;
  border: 1px solid #EBEBEB;
  color: #888;
}

/* 弹窗底部样式end */
.red {
  color: #f00;
}


/* 删除弹窗 */
.ModalConfirm-pic {
  display: block;
}

.ModalConfirm-pic .ant-modal-confirm-body {
  text-align: center;
}

.ModalConfirm-pic .anticon {
  color: #3E78ED !important;
  float: initial !important;
  vertical-align: top;
  margin-top: 2.5px;
  margin-right: 10px;
}

.ModalConfirm-pic .ant-modal-confirm-body .ant-modal-confirm-title {
  display: inline-block !important;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
}

.ModalConfirm-pic .ant-modal-confirm-content {
  margin: 15px 0 15px !important;
}

.ModalConfirm-pic .ant-modal-confirm-btns .ant-btn {
  padding: 8px 27px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-sizing: border-box;
  border-radius: 5px;
  height: 39px;
  cursor: pointer;
}

.ModalConfirm-pic .ant-modal-confirm-btns .ant-btn span {
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  color: #888888;
}

.ModalConfirm-pic .ant-modal-confirm-btns {
  float: initial !important;
  text-align: center !important;
}

.ModalConfirm-pic .ant-btn.ant-btn-primary,
.ModalConfirm-pic .ant-btn.ant-btn-danger {
  background: #3E78ED;
  border-radius: 5px;
  margin-left: 26px;
}

.ModalConfirm-pic .ant-btn.ant-btn-primary span,
.ModalConfirm-pic .ant-btn.ant-btn-danger span {
  color: #ffffff;
}

/*分页*/
.course-list-lh {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.course-list-lh .ant-pagination-options {
  margin-top: -4px;
  float: right !important;
}

.course-list-lh .ant-select-selection {
  border-radius: 3px;
  border: 1px solid #DCDFE6;
  height: 29px;
  margin-top: 3px;
}

.course-list-lh .ant-select-dropdown-menu-item {
  text-align: left !important;
}

/*空数据*/
.Empty-conter {
  height: calc(100vh - 488px);
  display: flex;
  align-content: center;
  justify-content: center;
}

.Empty-conter .stu_live_empty {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.Empty-conet {
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.Empty-conet .img {
  display: block;
  text-align: center;
}

.Empty-conet .img img {
  width: 200px;
  height: 117px;
  margin: 0 auto;
}

.Empty-conet span {
  font-size: 14px;
  line-height: 120.7%;
  color: #666666;
  margin-top: 20px;
  display: block;
}

/*加载中*/
.Spin-content {
  min-height: 300px;
}

.ant-modal-close-x {
  visibility: hidden;
}

html {
  background: #f6f7fb;
}

.action {
  font-family: Noto Sans SC;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #3e78ed;
  cursor: pointer;
}
.vertical_j span:not(:last-child)::after {
  content: "|";
  padding: 0px 7px;
  color: #ccc;
  position: relative;
  bottom: 1px;
}